import * as React from "react";
import PropTypes from "prop-types";
import FullWidthImage from "./FullWidthImage";
import FullWidthVideo from "./FullWidthVideo";

const DisplayHeroVideoOrImage = ({
  heroVideo,
  heroImage,
  title,
  subtitle,
  showTitle,
  showTitlesSwapped,
  options = [],
  header,
}) => {
  if (options.showTitle) {
    showTitle = options.showTitle;
  }
  if (options.showTitlesSwapped) {
    showTitle = options.showTitlesSwapped;
  }

  return (
    <>
      {heroVideo ? (
        <div className="video-wrapper">
          <FullWidthVideo
            video={heroVideo}
            title={title}
            subheading={subtitle}
            imgPosition="center"
            showTitle={showTitle}
            showTitlesSwapped={showTitlesSwapped}
            header={header}
          />
        </div>
      ) : (
        <>
          <div className="image-wrapper">
            {heroImage ? (
              <FullWidthImage
                img={heroImage}
                title={title}
                subheading={subtitle}
                imgPosition="center"
                showTitle={showTitle}
                showTitlesSwapped={showTitlesSwapped}
                header={header}
              />
            ) : null}
          </div>
        </>
      )}{" "}
    </>
  );
};

DisplayHeroVideoOrImage.propTypes = {
  heroVideo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showTitle: PropTypes.bool,
};

export default DisplayHeroVideoOrImage;
