import React from "react";
import PropTypes from "prop-types";

export default function FullWidthVideo(props) {
  const {
    video,
    title,
    subheading,
    detail = "",
    showTitle = true,
    showTitlesSwapped = false,
    className = "",
  } = props;

  let hiddenState = "is-hidden";
  if (showTitle) {
    hiddenState = "";
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="vert-center-container video">
          <video
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            preload="auto"
            id="heroVideo"
            className="video-responsive"
          >
            <source src={video} />
          </video>
          <div
            className={`vert-center-text ${
              showTitlesSwapped ? "show-titles-swapped" : null
            }`}
          >
            {showTitlesSwapped ? (
              <>
                {subheading && (
                  <h2
                    className={`page-subtitle video-subtitle ${hiddenState} ${className}`}
                  >
                    {subheading}
                  </h2>
                )}
                {title && (
                  <h1
                    className={`has-text-weight-bold page-title video-title ${hiddenState} ${className}`}
                  >
                    {title}
                  </h1>
                )}
              </>
            ) : (
              <>
                {title && (
                  <h1
                    className={`has-text-weight-bold page-title video-title ${hiddenState} ${className}`}
                  >
                    {title}
                  </h1>
                )}{" "}
                {subheading && (
                  <h2
                    className={`page-subtitle video-subtitle ${hiddenState} ${className}`}
                  >
                    {subheading}
                  </h2>
                )}
              </>
            )}

            {detail ? (
              <h3 className={`page-detail ${hiddenState} ${className}`}>
                {detail}
              </h3>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

FullWidthVideo.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
