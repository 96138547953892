import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({
  title,
  summary,
  image,
  article,
  tags = [],
  keywords = "",
  ldJson = "",
}) => {
  let { pathname } = useLocation();

  let { site, markdownRemark, blog, config } = useStaticQuery(query);

  // eslint-disable-next-line
  let {
    separator,
    description,
    configTitle,
    configSiteUrl,
    og,
    configKeywords,
  } = markdownRemark.frontmatter.site;
  // eslint-disable-next-line
  let { defaultSummary, siteUrl, defaultSiteName } = site.siteMetadata.property;

  // remove the trailing / from siteUrl if it exists

  if (!siteUrl) {
    siteUrl = configSiteUrl;
  }

  if (siteUrl.substring(siteUrl.length - 1) === "/") {
    siteUrl = siteUrl.substring(0, siteUrl.length - 1);
  }

  config = config.frontmatter.titles;

  const defaultTwitterCard = site.siteMetadata.meta.twitterCard;
  let defaultTitle = site.siteMetadata.defaultTitle || configTitle;
  let defaultKeywords = site.siteMetadata.keywords || configKeywords;
  let twitterUsername = markdownRemark.frontmatter.social.twitter;

  const configShowImageAsOgImage = blog.frontmatter.showImageAsOgImage;

  twitterUsername = twitterUsername.replace("https://twitter.com/", "");

  let seoTitle = "";
  let seoImage = "";
  let titleCheck = "";

  if (!separator) {
    separator = " | ";
  } else {
    separator = " " + separator + " ";
  }
  if (title) {
    // prevent double titling
    titleCheck = title + separator;
    defaultTitle = defaultTitle.replace(titleCheck, "");

    titleCheck = separator + title;
    defaultTitle = defaultTitle.replace(titleCheck, "");

    if (title !== defaultTitle) {
      seoTitle = title + separator + defaultTitle;
    } else {
      seoTitle = title + separator + description;
    }
  } else {
    title = defaultTitle;
    seoTitle = defaultTitle;
  }

  // check for title strip

  let blogString = config.blogString;
  let showBlogInTitle = config.showBlogInTitle;

  if (blogString && !showBlogInTitle) {
    title = title.replace(blogString, "").trim();
    seoTitle = seoTitle.replace(blogString, "").trim();
  }

  // convert default keywords and keywords to array from if needed
  if (defaultKeywords && defaultKeywords.indexOf(",") !== -1) {
    defaultKeywords = defaultKeywords.split(",");
    defaultKeywords.map((e) => e.trim());
  }
  if (keywords && keywords.indexOf(",") !== -1) {
    keywords = keywords.split(",");
    keywords.map((e) => e.trim());
  }

  // default to siteMetadata
  if (site && site.siteMetadata && site.siteMetadata.defaultImage) {
    seoImage = siteUrl + site.siteMetadata.defaultImage;
  }
  // roll thru to config if exists
  if (og && og.image) {
    seoImage = og.image.childImageSharp.gatsbyImageData.images.fallback.src;
  }

  if (configShowImageAsOgImage && image) {
    seoImage = siteUrl + image;
  }

  if (!summary) {
    if (defaultSummary) {
      summary = defaultSummary;
    } else if (description) {
      summary = description;
    }
  }

  const seo = {
    title: seoTitle.trim(),
    summary: summary.trim(),
    description: description.trim(),
    image: seoImage || false,
    url: `${siteUrl}${pathname}`,
  };
  let ldJsonTitle;

  if (defaultSiteName) {
    ldJsonTitle = defaultSiteName;
  } else {
    ldJsonTitle = site.siteMetadata.defaultTitle;
  }
  if (defaultTitle) {
    ldJsonTitle = title + " | " + ldJsonTitle;
  }

  if (!ldJson) {
    ldJson = (
      <script type="application/ld+json">{`
       {"@context" : "http://schema.org",
        "@type" : "Organization",  
        "name" : "${ldJsonTitle}",
        "url" : "${seo.url}", 
        "logo": "${seo.image}" 
       }`}</script>
    );
  }

  if (!tags) {
    tags = [];
  }
  // default keywords at the end
  if (tags && defaultKeywords) {
    tags = tags.concat(defaultKeywords);
  }

  // explicit keywords at the front
  if (tags && keywords) {
    tags = keywords.concat(tags);
  }
  //remove duplicates and trim down to 15 keywords max
  if (tags && tags.length) {
    tags = [...new Set(tags)];

    if (tags.length > 15) {
      tags = tags.slice(0, 15);
    }
  }

  return (
    <Helmet>
      <title>{seo.title}</title>
      {seo.summary ? <meta name="description" content={seo.summary} /> : null}
      {tags.length ? (
        <meta
          name="keywords"
          content={tags.join(", ").replace("  ", " ").toLowerCase()}
        />
      ) : null}
      {seo.summary ? (
        <meta
          name="og_description"
          property="og:description"
          content={seo.summary}
        />
      ) : (
        <>
          {seo.description ? (
            <meta
              name="og_description"
              property="og:description"
              content={seo.description}
            />
          ) : null}
        </>
      )}
      <meta name="og_title" property="og:title" content={seo.title} />
      <meta name="og_url" property="og:url" content={seo.url} />
      <meta name="og_image" property="og:image" content={seo.image} />
      <meta name="image" content={seo.image} />
      {article ? (
        <meta name="og_type" property="og:type" content="article" />
      ) : null}
      <meta name="twitter:card" content={defaultTwitterCard} />
      {twitterUsername ? (
        <meta name="twitter:creator" content={twitterUsername} />
      ) : null}
      <meta name="twitter:title" content={seo.title} />
      {seo.summary ? (
        <meta name="twitter:description" content={seo.summary} />
      ) : null}
      <meta name="twitter:image" content={seo.image} />
      <link
        rel="alternate"
        type="application/rss+xml"
        title={`${defaultTitle} Blog RSS Feed`}
        href="/rss.xml"
      />
      {ldJson}
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultImage: image
        property {
          siteUrl: url
          defaultSummary: description
          defaultSiteName: siteName
        }
        meta {
          twitterCard
        }
        keywords
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "blog" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        titles {
          showBlogInTitle
          blogString
        }
      }
    }
    markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        site {
          configTitle: name
          configSiteUrl: url
          description
          separator
          keywords
        }
        og {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          imageHeight
          imageWidth
        }
        social {
          linkedin
          facebook
          twitter
          instagram
          youtube
          google
          reddit
        }
      }
    }
    blog: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "blog" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        showImageAsOgImage
      }
    }
  }
`;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  summary: null,
  image: null,
  article: false,
};
