import * as React from "react";
import PropTypes from "prop-types";
import { HTMLContent } from "./Content";

const DisplayContent = ({
  id,
  templateName,
  previewContent,
  body,
  layout = [],
  element = <></>,
  pagination = <></>,
  paginationPosition = "below",
  separator = <></>,
  pages = 1,
}) => {
  if (!templateName) {
    templateName = "display-content";
  }
  id = templateName;
  //console.log(layout);

  //
  // separator

  if (layout && layout.separator) {
    separator = <HTMLContent content={layout.separator} />;
  } else if (layout && layout.render && layout.render.separator) {
    separator = <HTMLContent content={layout.render.separator} />;
  }
  // content position (block relative to content)

  if (!layout.render) {
    layout.render = [];
  }
  if (!paginationPosition) {
    paginationPosition = layout.position;
  }
  if (!layout.position) {
    layout.position = paginationPosition;
  }

  return (
    <>
      <div className={`content-${id}-outer display-content-outer`}>
        {previewContent ? (
          // back-end preview
          <>
            <div className="display-content-inner">
              <div className={`content-${id}`}>{previewContent}</div>
            </div>
          </>
        ) : (
          // front end
          // content positioning for pageNav
          <>
            <div className="display-content-inner">
              {layout && layout.position === "above" && element ? (
                <>
                  {paginationPosition === "above" ||
                  paginationPosition === "both" ? (
                    <>
                      {pagination && pages > 1 ? pagination : null}
                      {pagination && pages > 1 ? separator : null}
                    </>
                  ) : null}
                  {element}
                  {paginationPosition === "below" ||
                  paginationPosition === "both" ? (
                    <>
                      {pagination && pages > 1 ? separator : null}
                      {pagination && pages > 1 ? pagination : null}
                    </>
                  ) : null}
                </>
              ) : null}

              {typeof body === "object" ? (
                <>{body}</>
              ) : (
                <HTMLContent className={`content-${id}`} content={body} />
              )}

              {layout && layout.position === "below" && element ? (
                <>
                  {paginationPosition === "above" ||
                  paginationPosition === "both" ? (
                    <>{pagination && pages > 1 ? pagination : null}</>
                  ) : null}
                  {separator}
                  {element}
                  {paginationPosition === "below" ||
                  paginationPosition === "both" ? (
                    <>
                      {pagination && pages > 1 ? separator : null}
                      {pagination && pages > 1 ? pagination : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  );
};

DisplayContent.propTypes = {
  id: PropTypes.string,
  previewContent: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  layout: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
  element: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default DisplayContent;
